import React, { RefObject, useRef, useState } from "react";
import api from "../../Services/apiSimulation";
import { Button, Form } from "react-bootstrap";
import { validateCNPJ } from "validations-br";
import LoginModal from "../LoginModal";

export default function FormsSimulador() {
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");

  const handleSendEmail = async (e: any) => {
    e.preventDefault();
    if (!name || !cnpj || !email) {
      alert("Por favor, preencha todos os dados.");
    } else if (!validateCNPJ(cnpj)) {
      alert("Por favor, insira um CNPJ válido.");
    } else {
      try {
        await api.post("/fortlev-solar", { name, cnpj, email });
        localStorage.setItem("FORTLEV_SOLAR_TOKEN", "true");
        alert("Seu acesso ao simulador de economia foi liberado!");
        window.location.reload();
      } catch (err) {
        console.log(err);
        alert("Ocorreu um erro ao enviar salvar seus dados. Tente novamente.");
      }
    }
  };

  const loginModalRef: RefObject<LoginModal> = useRef<LoginModal>(null);

  const onLoginClick = () => {
    loginModalRef.current?.handleShow();
  };

  return (
    <div
      className="modal-container"
      style={{ marginTop: "80px" }}
      id="simuladorId"
    >
      <div className="container">
        <div style={{ width: "100%", paddingTop: "40px" }}>
          <div className="container-modal">
            <Form onSubmit={handleSendEmail}>
              <Form.Group controlId="formGroupHeader">
                <Form.Label
                  className="text-center loginTitle"
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  Preencha o formulário para liberar o acesso ao simulador.
                </Form.Label>
                <Form.Label
                  className="text-center loginTitle"
                  style={{
                    width: "100%",
                    fontSize: "18px",
                    color: "#FFFFFF",
                  }}
                >
                  Preencha o formulário abaixo com seus dados, que iremos
                  liberar o acesso ao simulador de economia!
                </Form.Label>
              </Form.Group>

              <Form.Group controlId="talkName" className="center-input">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  required
                  type="text"
                  size="sm"
                  placeholder=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="talkEmail" className="center-input">
                <Form.Label>CNPJ</Form.Label>
                <Form.Control
                  required
                  type="text"
                  size="sm"
                  placeholder=""
                  value={cnpj}
                  onChange={(e) => {
                    setCnpj(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group controlId="talkEmail" className="center-input">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  required
                  type="email"
                  size="sm"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                className="center-input"
                style={{
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#00386d",
                    color: "#FFFFFF",
                    border: "none",
                  }}
                  className="register-button-fortlev register-cell"
                  type="submit"
                >
                  Enviar
                </Button>
              </Form.Group>
            </Form>

            <div className="forms-login">
              <h5>OU</h5>
              <h3 onClick={() => onLoginClick()}>Faça Login</h3>
            </div>
          </div>
        </div>
      </div>

      <LoginModal ref={loginModalRef}></LoginModal>
    </div>
  );
}
