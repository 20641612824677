import React, { Component } from "react";

import goCadAtivo from "../../Assets/cadAtivoGo.png";
import adesao from "../../Assets/adesao.png";

import "./style.css";

/*
import api from '../../Services/apiSimulation'

import { getToken } from '../../Services/auth';
*/

class CadastrosAtivos extends Component {
  constructor(props) {
    super();

    this.state = {
      clients: [
        {
          nome: "Alberto S/A",
          companyCNPJ: "Alberto SA Industria e Comercio",
        },
      ],
    };
  }

  async componentDidMount() {
    /*
        const my_token = getToken();
            
        try {

            var response = await api.get('/user', {
                headers: { authorization: 'Bearer ' + my_token }
            })
            

        } catch (err) {
            console.log(err);
            alert("Erro ao pegar o id do usuario");
        }

        const _id = response.data.user_id;

        try {
                
            const clientsList = await api.post('/getClientsFromPartner', { PartnerID: _id })
            this.setState( { clients: clientsList.data } )

        } catch (err) {
            console.log(err);
            alert("Erro ao pegar os dados do usuario");
        }
        */
  }

  render() {
    return (
      <div className="CadastrosAtivosContainerMaster">
        <div className="CadastrosAtivosContainer">
          <p className="CadastrosAtuvosContainer-title">Adesões</p>
          <p className="CadastrosAtuvosContainer-subtitle">
            Resumo de adesões em andamento vinculadas ao seu perfil de usuário
          </p>

          <div className="cadastrosAtivos-content">
            <div className="cadastrosAtivos-content-row-title">
              <div className="cadastrosAtivos-content-row-item-title-1">
                Sigla
              </div>
              <div className="cadastrosAtivos-content-row-item-title-2">
                Razão social
              </div>
              <div className="cadastrosAtivos-content-row-item-title-3">
                Status
              </div>
            </div>

            {this.state.clients.map((client) => {
              return (
                <div className="cadastrosAtivos-row" key={client.nome}>
                  <div className="cadastrosAtivos-row-content-item-1">
                    {client.nome}
                  </div>
                  <div className="cadastrosAtivos-row-content-item-2">
                    {client.companyCNPJ}
                  </div>
                  <div className="cadastrosAtivos-row-content-item-3">
                    <img
                      src={adesao}
                      alt="ok"
                      style={{ marginRight: "11px" }}
                    />{" "}
                    Em adesão
                  </div>
                  <div className="cadastrosAtivos-row-content-item-4">
                    <img src={goCadAtivo} alt="checkIt" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default CadastrosAtivos;
