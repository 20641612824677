import React, { useState, useEffect } from "react";
import NavBar from "../../Components/Navbar/index";

import ParceiroInfo from "../../Components/ParceiroInfo/index";
import CadastrosAtivos from "../../Components/CadastrosAtivos/index";
import AdesoesAtivas from "../../Components/AdesoesAtivas/index";

import PagamentosParceiro from "../../Components/PagamentosParceiro/index";
import PagamentosParceiroTable from "../../Components/PagamentosParceiroTable/index";

import Footer from "../../Components/Footer/index";

import "./style.css";

import api from "../../Services/apiSimulation";

import {
  getRefreshToken,
  getToken,
  getUserId,
  logout,
  updateAccessToken,
} from "../../Services/auth";
import CoustCardItem from "../../Components/CoustCard-item";

import pdfIcon from "../../Assets/pdficon.png";
import Header from "../../Components/Header";
import { Button, CloseButton, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContent } from "@material-ui/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SendModal from "../../SendModal";
import PartnerEditInfo from "../../Components/PartnerEditInfo";
import { FiDelete, FiDownload, FiTrash, FiTrash2 } from "react-icons/fi";
import DeletePartnerDocModal from "../../Components/DeletePartnerDocModal";

export default function Main() {
  const [partnerCompanyName, setPartnerCompanyName] = useState("");
  const [partnerCompanyCnpj, setPartnerCompanyCnpj] = useState("");
  const [partnerCompanyId, setPartnerCompanyId] = useState("");
  const [partnerDocuments, setPartnerDocuments] = useState([]);
  const [partnerName, setPartnerName] = useState("");
  const [userCpf, setUserCpf] = useState("");
  const [economiaTotal, setEconomiaTotal] = useState(0);
  const [valorRecebido, setValorRecebido] = useState(0);
  const [valorAReceber, setValorAReceber] = useState(0);
  const [logo, setLogo] = useState("");
  const [Affiliates, setAffiliates] = useState([]);
  const [partnerInfo, setPartnerInfo] = useState({});

  const [economy, setEconomy] = useState(0);
  const [docs, setDocs] = useState(1);
  const [Sigla, setSigla] = useState("");

  // const [companyCNPJ, setCompCNPJ] = useState("");
  // const [Submercado, setSubmercado] = useState("");
  // const [Classe, setClasse] = useState("");
  // const [Categoria, setCategoria] = useState("");
  // const [userData, setUserData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const my_token = getToken();
        const id = getUserId();

        var response = await api.get(`/users/${id}`, {
          headers: { authorization: "Bearer " + my_token },
        });

        setPartnerInfo(response.data);
        setPartnerName(response.data.name);
        setPartnerDocuments(response.data.partner_company.documents);
        setPartnerCompanyId(response.data.partner_company.id);
        setPartnerCompanyName(response.data.partner_company.name);
        setPartnerCompanyCnpj(response.data.partner_company.cnpj);
        setValorRecebido(response.data.partner_company.total_received);
        setValorAReceber(response.data.partner_company.total_to_be_received);
        setSigla(response.data.partner_company.initials);
        setAffiliates(response.data.partner_company.affiliates);
        setLogo(response.data.partner_company.logo);
        var partnerResponse = await api.get(
          `/partner-companies/${response.data.partner_company.id}`,
          {
            headers: { authorization: "Bearer " + my_token },
          }
        );
        setEconomiaTotal(partnerResponse.data.clients_economy_accumulated);
      } catch (e) {
        console.log("ERRO");
        //logout();
        //window.location.reload();
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatCurrency(n) {
    if (n) {
      return new Intl.NumberFormat("pt-BR", {}).format(n);
    }
  }

  // Função para formatar CPF
  function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  // Função para formatar CNPJ
  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showDeleteDoc, setShowDeleteDoc] = useState(false);
  const [loadingDeleteDoc, setLoadingDeleteDoc] = useState(false);

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const handleCloseDoc = () => {
    setShowDeleteDoc(false);
    window.location.reload();
  };

  const handleShow = () => setShow(true);
  const [error, setError] = useState(false);
  const [errorDeleteDoc, setErrorDeleteDoc] = useState(false);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleUpload = async () => {
    setFileLoading(true);

    const token = getToken();
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    try {
      if (selectedFiles.length <= 0) {
        window.alert("Selecione algum documento para enviar!");
        setFileLoading(false);
        return;
      }

      await api.patch(
        `/partner-companies/sent/documents/partner/${partnerCompanyId}`,
        formData,
        {
          headers,
        }
      );
      setFileLoading(false);
      setSelectedFiles([]);
      setShow(true);
    } catch (error) {
      setShow(true);
      setError(true);
      setFileLoading(false);
      setSelectedFiles([]);
    }
  };

  const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  function handleDeleteConfirmation(item) {
    setModalDeleteConfirmation(true);
    setDeleteItem(item);
  }

  const handleCloseDeleteDoc = () => setModalDeleteConfirmation(false);

  async function handleDeleteDoc(file) {
    setModalDeleteConfirmation(false);
    setLoadingDeleteDoc(true);

    const token = getToken();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await api.delete(`/uploads/${file}?folder=documents`, {
        headers,
      });

      setShowDeleteDoc(true);
      setLoadingDeleteDoc(false);
    } catch (e) {
      setErrorDeleteDoc(true);
      setShowDeleteDoc(true);
      setLoadingDeleteDoc(false);
    }
  }

  function convertDate(date) {
    return `${new Date(date).toLocaleDateString("pt-br").split(",")[0]}`;
  }

  function downloadPdf(url) {
    window.open(url, "_blank");
  }

  return (
    <>
      <NavBar changeColor="true" />
      <div className="container">
        <div className="partner-area" style={{ marginTop: "60px" }}>
          <div className="partner-info-company">
            <h1 className="title-partner">Área do parceiro</h1>
            <div className="data-info">
              <div className="name-info">
                <h2 className="gray-text">Nome</h2>
                <h2 className="bold-text">{partnerName}</h2>
              </div>
              <div className="cpf-info">
                <h2 className="gray-text">Empresa</h2>
                <h2 className="bold-text">{partnerCompanyName}</h2>
              </div>
              <div className="cnpj-info">
                <h2 className="gray-text">CNPJ</h2>
                <h2 className="bold-text">{formatCNPJ(partnerCompanyCnpj)}</h2>
              </div>
            </div>
            {partnerName && <PartnerEditInfo partnerInfo={partnerInfo} />}
            {/* <span className="btn-edit">EDITAR</span> */}
          </div>

          <div className="logo">
            {logo && <img src={logo} alt="Logo empresa" />}
          </div>
        </div>

        <div className="economy-area">
          {/* <div className="value-card">
            {/* <p className="total-economy"> */}
          {/*{economiaTotal ? (
              <>
                <h2>Economia total dos clientes indicados</h2>
                <p>R$ {formatCurrency(economiaTotal)}</p>
              </>
            ) : (
              <>
                <h2>Economia total dos clientes indicados</h2>
                <p>R$ 0,00</p>
              </>
            )}
          </div> */}
          {/* 
          <div className="value-card">
            <h2>Valor total a receber</h2>
            {valorAReceber ? (
              <p>{`R$ ${formatCurrency(valorAReceber)}`}</p>
            ) : (
              <p>R$ 0,00</p>
            )}
          </div> */}
        </div>
        {/* 
        <div className="box-center">
          <span className="total-economy">
            {economiaTotal
              ? `Economia total dos clientes indicados R$ ${formatCurrency(
                  economiaTotal
                )}`
              : "Economia total dos clientes indicados R$ 0,00"}
          </span>
        </div> */}
        <div className="container">
          <CadastrosAtivos
            Affiliates={Affiliates}
            economiaTotal={economiaTotal}
          />
        </div>
        <SendModal
          error={error}
          show={show}
          onHide={handleClose}
          setShow={setShow}
          setError={setError}
        />
        <DeletePartnerDocModal
          error={errorDeleteDoc}
          onHide={handleCloseDoc}
          show={showDeleteDoc}
          setShow={setShowDeleteDoc}
          setError={setErrorDeleteDoc}
        />

        <Modal
          show={modalDeleteConfirmation}
          onHide={() => setModalDeleteConfirmation(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Deletar {deleteItem.name}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "8px" }}>
            Você tem certeza que você deseja deletar o documento{" "}
            {deleteItem.name}? Esta ação é irreversível.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleCloseDeleteDoc}>
              Cancelar
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDeleteDoc(deleteItem?.storage_id)}
            >
              Deletar
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="docs-area">
          <div className="docs-text-area">
            <h2>Documentos recebidos</h2>
            <p className="mb">
              Visualize todos os documentos em PDF que a MERX enviou para você
            </p>
            <div className="pdf-area">
              {partnerDocuments.length > 0 ? (
                <>
                  {partnerDocuments.map((item, index) => {
                    if (item.situation === "RECEBIDO") {
                      return (
                        <div key={index} className="pdf-material">
                          <div className="pdf-image">
                            <img className="pdf-icon" src={pdfIcon} alt="pdf" />
                          </div>
                          <div className="pdf-info">
                            <span className="pdf-name">{item.name}</span>
                            <span className="pdf-size size-color">
                              Tamanho {formatCurrency(+item.size)} kB
                            </span>
                            <span className="pdf-size mb-pdf">
                              Recebido em {convertDate(item.created_at)}
                            </span>
                          </div>
                          <div className="button-area">
                            <FiDownload
                              style={{ cursor: "pointer", color: "#000" }}
                              onClick={() => downloadPdf(item.url)}
                            />
                            {!loadingDeleteDoc && (
                              <FiTrash2
                                style={{
                                  color: "#CF0E0E",
                                  marginLeft: "8px",
                                  cursor: loadingDeleteDoc ? "none" : "pointer",
                                }}
                                onClick={() => handleDeleteConfirmation(item)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              ) : (
                <span className="no-content">
                  Não há nenhum documento recebido.
                </span>
              )}
            </div>
          </div>

          <div className="divisor-bar"></div>

          <div className="docs-text-area">
            <h2>Documentos enviados</h2>
            <p className="mb">
              Envie e visualize documentos em PDF que você enviou para a MERX
            </p>

            {selectedFiles.length === 0 && (
              <p style={{ fontSize: 13.5 }}>Nenhum arquivo selecionado</p>
            )}
            {selectedFiles.length > 0 && (
              <p style={{ fontSize: 13.5 }}>
                {selectedFiles.length} arquivo(s) selecionado(s)
              </p>
            )}

            <span className="send-btn">
              SELECIONAR ARQUIVOS
              <input
                className="input-file"
                type="file"
                multiple
                onChange={handleFileSelect}
                accept="application/pdf"
              />
            </span>

            <button
              disabled={fileLoading}
              className={!fileLoading ? "send-btn-button" : "button-loading"}
              onClick={handleUpload}
            >
              {!fileLoading ? (
                "ENVIAR"
              ) : (
                <Spinner animation="border" variant="primary"></Spinner>
              )}
            </button>

            <div style={{ marginTop: "40px" }}></div>

            <div className="pdf-area">
              {partnerDocuments.length > 0 ? (
                <>
                  {partnerDocuments.map((item, index) => {
                    if (item.situation === "ENVIADO") {
                      return (
                        <div key={index} className="pdf-material">
                          <div className="pdf-image">
                            <img className="pdf-icon" src={pdfIcon} alt="pdf" />
                          </div>
                          <div className="pdf-info">
                            <span className="pdf-name">{item.name}</span>
                            <span className="pdf-size size-color">
                              Tamanho {formatCurrency(+item.size)} kB
                            </span>
                            <span className="pdf-size mb-pdf">
                              Enviado em {convertDate(item.created_at)}
                            </span>
                          </div>
                          <div className="button-area">
                            <FiDownload
                              style={{ cursor: "pointer", color: "#000" }}
                              onClick={() => downloadPdf(item.url)}
                            />
                            {!loadingDeleteDoc && (
                              <FiTrash2
                                style={{
                                  color: "#CF0E0E",
                                  marginLeft: "8px",
                                  cursor: loadingDeleteDoc ? "none" : "pointer",
                                }}
                                onClick={() => handleDeleteConfirmation(item)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              ) : (
                <span className="no-content">
                  Não há nenhum documento enviado.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="cursos-section">
        <div className="videos-container">
          <div className="container">
            <h1>Cursos</h1>
            <h2>Usufrua de todos os nossos cursos de capacitação exclusivos</h2>

            <div className="videos-grid">
              <div className="videos-container-section">
                <iframe
                  className="parceiro-iframe"
                  title="Video"
                  src="https://player.vimeo.com/video/812441982?h=5222708012"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
                {/* <h3>NÃO VISUALIZADO</h3> */}
              </div>
              <div className="videos-container-section">
                <iframe
                  className="parceiro-iframe"
                  title="Video"
                  src="https://player.vimeo.com/video/812445323?h=647efc298b"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
                {/* <h3>NÃO VISUALIZADO</h3> */}
              </div>
            </div>
          </div>
        </div>
        <div className="videos-container">
          <div className="container">
            <h1>Materiais</h1>
            <h2>Recursos para vendas e outros negócios</h2>
            <div className="material-grid">
              <span className="no-content" style={{ fontSize: 18 }}>
                Em breve serão postados materiais aqui
              </span>
              {/* <div className="pdf-card">
                <div className="pdf-info">
                  <img src={pdfIcon} alt="not found icon" width="40" />
                  <div className="pdf-card-text">
                    <span className="title-card">
                      Merx - Apresentação Final
                    </span>
                    <span className="description-card">120kB</span>
                  </div>
                </div>
                <a
                  className="pdf-link"
                  href="https://drive.google.com/drive/folders/1pFsx9gnXgE4qyBLI5w5EhZriP_VDBBUK?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Baixar</span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
