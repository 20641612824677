import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import {
  ContainerArea,
  Main,
  ContentContainer,
  AvatarContainer,
  ContentCard,
  PageTitle,
  PageTitleContainer,
  ContainerDadoBasico,
  DadoBasico,
  TitleDadoBasico,
  ImgRegiao,
  ContainerDadosRegiao,
  DummyImg,
  ClientNameInfo,
  ClientText,
  ContractInfo,
  StatusMigrationArea,
  CadastrosItem,
  CadastrosItemInfo,
  CadastrosItem1,
  CadastrosItem2,
  CadastrosItem3,
  CadastrosItem4,
  UnidadesText,
} from "./styled";
import arrowup from "../../Assets/arrowup.svg";
import arrowdown from "../../Assets/arrowdown.svg";
import { LogoArea } from "../ClientDashboard/styled";
import cadAtivoOk from "../../Assets/cadAtivoOk.png";
import styled from "styled-components";
import arrowleft from "../../Assets/arrowleft.png";
import iconpending from "../../Assets/iconpending.png";
import iconchecked from "../../Assets/iconcheck.png";
import iconapproved from "../../Assets/iconapproved.svg";

import api from "../../Services/apiSimulation";
import merxlogo from "../../Assets/blackLogo.png";
import { IClient, IMonthlyData, IPrev } from "./interfaces";
import Timeline from "../../Components/Dashboard/Timeline";
import EconomiaMensal from "../../Components/Dashboard/EconomiaMensal";
import Lastro from "../../Components/Dashboard/Lastro";
import CustoEconomia from "../../Components/Dashboard/CustoEconomia";
import ConsumeLine from "../../Components/ConsumeLine/index";
import XPTO from "../../Components/XPTO/index";
import CardsGrid from "../../Components/CardsGrid/index";
import { getToken } from "../../Services/auth";
import ProjecaoConsumo from "../../Components/Dashboard/ProjecaoConsumo";
import Historico from "../../Components/Dashboard/Historico";
import PrevisaoConsumo from "../../Components/Dashboard/PrevisaoConsumo";
import { EvolucaoConsumo } from "../../Components/Dashboard/EvolucaoConsumo";
import { Accordion } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ModalLarge from "../../Components/ModalLarge";
import CustoEconomiaUnidadeConsumidora from "../../Components/Dashboard/CustoEconomiaUnidadeConsumidora";
import LastroMeasuringPoint from "../../Components/Dashboard/LastroMeasuringPoint";
import EconomiaMensalMeasuringPoint from "../../Components/Dashboard/EconomiaMensalMeasuringPoint";
import { DemandaMeasuringPoint } from "../../Components/Dashboard/DemandaMeasuringPoint";
import { EvolucaoConsumoMeasuringPoint } from "../../Components/Dashboard/EvolucaoConsumoMeasuringPoint";
import { ConsumoReativoMeasuringPoint } from "../../Components/Dashboard/ConsumoReativoMeasuringPoint";
import { DemandaForaPontaMeasuringPoint } from "../../Components/Dashboard/DemandaForaPontaMeasuringPoint";

function formatCNPJ(input: string | undefined) {
  if (input) {
    return input.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return "";
  }
}

function parseRegiao(input: string) {
  const inputUC = input.toUpperCase();

  if (inputUC === "CENTRO-OESTE" || inputUC === "CENTROOESTE") {
    return "centroOeste";
  } else if (inputUC === "NE" || inputUC === "NORDESTE") {
    return "nordeste";
  } else if (inputUC === "NORTE") {
    return "norte";
  } else if (inputUC === "SUL") {
    return "sul";
  } else if (inputUC === "SUDESTE" || inputUC === "SE") {
    return "sudeste";
  } else {
    return "error";
  }
}

function RenderRegion(regiao: string) {
  if (regiao === "centroOeste") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_centro-oeste.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else if (regiao === "nordeste") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_nordeste.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else if (regiao === "norte") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_norte.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else if (regiao === "sudeste") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_sudeste.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else if (regiao === "sul") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_sul.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else {
    return <DummyImg></DummyImg>;
  }
}

function backPage() {
  window.history.back();
}

function GenerateDadoBasico(name: string, value: string | undefined) {
  return (
    <ContainerDadoBasico>
      <TitleDadoBasico>
        <strong>{name}</strong>
      </TitleDadoBasico>
      <DadoBasico>{value}</DadoBasico>
    </ContainerDadoBasico>
  );
}

const PerfilPage = () => {
  const [companyData, setCompanyData] = useState<any>();
  const [companyCNPJ, setCompanyCNPJ] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [openPerfilModal, setOpenPerfilModal] = useState(false);
  const [profileInfo, setProfileInfo] = useState<any | null>(null);
  const [measuringPoints, setMeasuringPoints] = useState<any | null>([]);

  const { profileId } = useParams<{ profileId?: string }>();

  useEffect(() => {
    const fetchData = async () => {
      // try {
      const token = getToken();
      const id = window.localStorage.getItem("id");
      const userResponse = await api.get(`users/${id}`, {
        headers: { authorization: "Bearer " + token },
      });

      const measuringPointsAssociatedWithProfileId = await api.get(
        `measuring-point/${profileId}`,
        {
          headers: { authorization: "Bearer " + token },
        }
      );

      setMeasuringPoints(measuringPointsAssociatedWithProfileId.data);

      setCompanyLogo(userResponse.data.company.logo);
      setCompanyData(userResponse.data.company);
    };
    fetchData().catch(console.error);
  }, [profileId]);

  function convertDate(date: string) {
    return `${new Date(date).toLocaleDateString("pt-br").split(",")[0]}`;
  }

  function formatCNPJ(input: string | undefined) {
    if (input) {
      return input.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    } else {
      return "";
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <Main>
      <Navbar changeColor="true" />
      <div className="container">
        <LogoArea style={{ marginTop: "50px" }}>
          <img
            className="back"
            src={arrowleft}
            alt="Voltar"
            onClick={backPage}
          />
          <div className="back-area" onClick={backPage}>
            <span>Voltar</span>
          </div>
        </LogoArea>

        {measuringPoints && <ClientText>{measuringPoints?.name}</ClientText>}

        {/* {profileInfo && (
          <ClientNameInfo>
            <div className="client-info">
              <div className="logo">
                <img src={companyLogo} alt="Logo" />
              </div>
              <div className="client-name-area">
                <span>{profileInfo?.name}</span>
                <p>{formatCNPJ(profileInfo?.cnpj)}</p>
              </div>
            </div>
          </ClientNameInfo>
        )}

        <div className="info-contrato-container">
          <div className="info-contrato">
            <div className="info-contrato-item">
              <span>SUBMERCADO</span>
              <p>{profileInfo?.submarket}</p>
            </div>
            <div className="info-contrato-item">
              <span>CLASSE</span>
              <p>{profileInfo?.class.split("_").join(" ")}</p>
            </div>
            <div className="info-contrato-item">
              <span>CATEGORIA</span>
              <p>{profileInfo?.category}</p>
            </div>
          </div>
          <div className="info-contrato">
            <div className="info-contrato-item3">
              <span>CÓDIGO</span>
              <p>{profileInfo?.code}</p>
            </div>
            <div className="info-contrato-item2">
              <span>UNIDADES CONSUMIDORAS ATIVAS</span>
              <p>{measuringPoints?.length}</p>
            </div>
          </div>
        </div> */}

        {/* <UnidadesText>Unidades consumidoras</UnidadesText> */}

        <div style={{ width: "100%" }}>
          <div style={{ backgroundColor: "#f5f5f5", padding: "30px 25px" }}>
            {Array.from({ length: 1 }).map((_, index) => {
              return (
                <>
                  {companyData?.check?.aprovacaoCheck ? (
                    measuringPoints?.invoices.length > 0 && (
                      <>
                        <div
                          className="cadastros-container-section"
                          style={{ marginBottom: "16px" }}
                        >
                          <h1>Detalhamento</h1>
                          <h2>
                            Acompanhe o desempenho econômico, relatório de
                            faturas e contribuição associativa CCEE da unidade
                            consumidora {measuringPoints.name.toUpperCase()}
                          </h2>
                        </div>

                        <div style={{ position: "relative", top: "65px" }}>
                          <span className="clientDashboard-text">
                            Custo mensal acumulado
                          </span>
                        </div>

                        {
                          <CustoEconomiaUnidadeConsumidora
                            totalCativo={measuringPoints.totalCostCativo}
                            totalLivre={measuringPoints.totalCostLivre}
                            economy={measuringPoints.accumulatedEconomy}
                          />
                        }

                        <div
                          style={{
                            margin: "32px 0 16px 0",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="clientDashboard-text"
                            style={{ fontWeight: "bold" }}
                          >
                            Balanço energético mensal
                          </span>
                          <span
                            className="clientDashboard-text"
                            style={{ fontSize: "16px" }}
                          >
                            {" "}
                            Contrato, consumo e ajuste de energia
                          </span>
                        </div>
                        <div className="clientDashboard-container">
                          <EvolucaoConsumoMeasuringPoint
                            monthData={measuringPoints?.invoices}
                          />
                        </div>

                        {measuringPoints?.invoices.every(
                          (invoice: any) => invoice.contractedPeakDemand === 0
                        ) ? null : (
                          <>
                            <div
                              style={{
                                margin: "32px 0 16px 0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="clientDashboard-text"
                                style={{ fontWeight: "bold" }}
                              >
                                Análise temporal da demanda ponta
                              </span>
                            </div>

                            <div className="clientDashboard-container">
                              <DemandaMeasuringPoint
                                monthData={measuringPoints?.invoices}
                              />
                            </div>
                          </>
                        )}

                        <div
                          style={{
                            margin: "32px 0 16px 0",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="clientDashboard-text"
                            style={{ fontWeight: "bold" }}
                          >
                            Análise temporal da demanda fora ponta
                          </span>
                        </div>

                        <div className="clientDashboard-container">
                          <DemandaForaPontaMeasuringPoint
                            monthData={measuringPoints?.invoices}
                          />
                        </div>

                        <div
                          style={{
                            margin: "32px 0 16px 0",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="clientDashboard-text"
                            style={{ fontWeight: "bold" }}
                          >
                            Análise temporal do consumo reativo
                          </span>
                        </div>

                        <div className="clientDashboard-container">
                          <ConsumoReativoMeasuringPoint
                            monthData={measuringPoints?.invoices}
                          />
                        </div>

                        {/* <div style={{ margin: "16px 0" }}>
                <span className="clientDashboard-text">Lastro</span>
              </div>
              <div className="clientDashboard-container">
                <LastroMeasuringPoint monthData={measuringPoints?.invoices} />
              </div> */}
                        <div
                          style={{
                            margin: "32px 0 16px 0",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="clientDashboard-text"
                            style={{ fontWeight: "bold" }}
                          >
                            Economia mensal
                          </span>
                        </div>
                        <div className="clientDashboard-container">
                          <EconomiaMensalMeasuringPoint
                            monthData={measuringPoints?.invoices}
                          />
                        </div>
                        <div
                          style={{
                            margin: "32px 0 16px 0",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span className="clientDashboard-text">
                            Faturas mensais e contribuição associativa CCEE
                          </span>
                        </div>
                        <CardsGrid monthData={measuringPoints?.invoices} />
                      </>
                    )
                  ) : (
                    <div className="right-text-center">
                      <h1>
                        Histórico e gráficos serão disponibilizados após a
                        migração ser concluída
                      </h1>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>

        {/* {prevData && <ProjecaoConsumo prevData={prevData} />} */}
        {/* {prevData && <XPTO prevData={prevData} />} */}
        {/* {monthlyData && <Historico monthlyData={monthlyData} />} */}
      </div>
      <Footer />
    </Main>
  );
};

export default PerfilPage;
