import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import {
  AgentResultTeste,
  PerfilContainer,
  ContainerArea,
  Main,
  ContentContainer,
  AvatarContainer,
  ContentCard,
  PageTitle,
  PageTitleContainer,
  ContainerDadoBasico,
  DadoBasico,
  TitleDadoBasico,
  ImgRegiao,
  ContainerDadosRegiao,
  DummyImg,
  ClientNameInfo,
  ClientText,
  ContractInfo,
  StatusMigrationArea,
  CadastrosItem,
  CadastrosItemInfo,
  CadastrosItem1,
  CadastrosItem2,
  CadastrosItem3,
  CadastrosItem4,
} from "./styled";
import arrowup from "../../Assets/arrowup.svg";
import arrowdown from "../../Assets/arrowdown.svg";
import { LogoArea } from "../ClientDashboard/styled";
import cadAtivoOk from "../../Assets/cadAtivoOk.png";
import styled from "styled-components";
import arrowleft from "../../Assets/arrowleft.png";
import iconpending from "../../Assets/iconpending.png";
import iconchecked from "../../Assets/iconcheck.png";

import iconapproved from "../../Assets/iconapproved.svg";
import iconClientApproved from "../../Assets/client-approved.png";
import iconClientBankAccount from "../../Assets/client-bank-account.png";
import iconClientBolt from "../../Assets/client-bolt.png";
import iconClientElectricVehicle from "../../Assets/client-electric-vehicle.png";
import iconClientHint from "../../Assets/client-hint.png";
import iconClientSocket from "../../Assets/client-socket.png";

import api from "../../Services/apiSimulation";
import merxlogo from "../../Assets/blackLogo.png";
import { IClient, IMonthlyData, IPrev } from "./interfaces";
import Timeline from "../../Components/Dashboard/Timeline";
import EconomiaMensal from "../../Components/Dashboard/EconomiaMensal";
import Lastro from "../../Components/Dashboard/Lastro";
import CustoEconomia from "../../Components/Dashboard/CustoEconomia";
import ConsumeLine from "../../Components/ConsumeLine/index";
import XPTO from "../../Components/XPTO/index";
import CardsGrid from "../../Components/CardsGrid/index";
import { getToken } from "../../Services/auth";
import ProjecaoConsumo from "../../Components/Dashboard/ProjecaoConsumo";
import Historico from "../../Components/Dashboard/Historico";
import PrevisaoConsumo from "../../Components/Dashboard/PrevisaoConsumo";
import { EvolucaoConsumo } from "../../Components/Dashboard/EvolucaoConsumo";
import {
  Accordion,
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "../Client/style.css";
import "../../Components/CoustCardRow/style.css";
import { formatCNPJ } from "../../utils/utilityFunctions";
import { StatusMigrationAreaComponent } from "./StatusMigrationAreaComponent";

function parseRegiao(input: string) {
  const inputUC = input.toUpperCase();

  if (inputUC === "CENTRO-OESTE" || inputUC === "CENTROOESTE") {
    return "centroOeste";
  } else if (inputUC === "NE" || inputUC === "NORDESTE") {
    return "nordeste";
  } else if (inputUC === "NORTE") {
    return "norte";
  } else if (inputUC === "SUL") {
    return "sul";
  } else if (inputUC === "SUDESTE" || inputUC === "SE") {
    return "sudeste";
  } else {
    return "error";
  }
}

function RenderRegion(regiao: string) {
  if (regiao === "centroOeste") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_centro-oeste.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else if (regiao === "nordeste") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_nordeste.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else if (regiao === "norte") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_norte.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else if (regiao === "sudeste") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_sudeste.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else if (regiao === "sul") {
    return (
      <ImgRegiao
        src={require("../../Assets/regioes/regiao_sul.svg")}
        alt=""
      ></ImgRegiao>
    );
  } else {
    return <DummyImg></DummyImg>;
  }
}

function backPage() {
  window.history.back();
}

function GenerateDadoBasico(name: string, value: string | undefined) {
  return (
    <ContainerDadoBasico>
      <TitleDadoBasico>
        <strong>{name}</strong>
      </TitleDadoBasico>
      <DadoBasico>{value}</DadoBasico>
    </ContainerDadoBasico>
  );
}

const ClientDashboard = () => {
  const [companyData, setCompanyData] = useState<any>();
  const [companyCNPJ, setCompanyCNPJ] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [profiles, setProfiles] = useState<any[] | null>(null);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [openPerfilModal, setOpenPerfilModal] = useState(true);
  const [userData, setUserData] = useState<any>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      // try {
      const token = getToken();
      const id = window.localStorage.getItem("id");
      const userResponse = await api.get(`users/${id}`, {
        headers: { authorization: "Bearer " + token },
      });

      setUserData(userResponse?.data);

      const companyResponse = await api.get(
        `companies/${userResponse.data.company.id}`,
        {
          headers: { authorization: "Bearer " + token },
        }
      );

      setCompanyLogo(userResponse.data.company.logo);
      setCompanyData(companyResponse.data);
      let measuringPoints = [];
      for (let i = 0; i < companyResponse.data.profiles.length; i++) {
        for (
          let j = 0;
          j < companyResponse.data.profiles[i].measuring_points.length;
          j++
        ) {
          measuringPoints.push(
            companyResponse.data.profiles[i].measuring_points[j]
          );
        }
      }
      setProfiles(measuringPoints);
    };
    fetchData().catch(console.error);
  }, []);

  function convertDate(date: string) {
    return `${new Date(date).toLocaleDateString("pt-br").split(",")[0]}`;
  }

  const [show, setShow] = useState(false);
  const [name, setName] = useState(userData?.name);
  const [email, setEmail] = useState(userData?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearStates = () => {
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setDisabled(false);
  };

  const saveInfo = async () => {
    setDisabled(true);

    if (password !== confirmPassword) {
      window.alert("As duas senhas precisam ser iguais!");
      clearStates();
      handleClose();
      return;
    }

    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const data = {
      password: password ? password : undefined,
      name: name ? name : undefined,
      email: email ? email : undefined,
    };

    try {
      await api.patch(`/users/${userData.id}`, data, {
        headers,
      });

      handleClose();
      clearStates();
      window.alert("Suas novas informações foram salvas com sucesso!");
      window.location.reload();
    } catch (e) {
      window.alert(
        "Erro ao tentar salvar suas informações, por favor, verifique se os campos estão corretos!"
      );
      handleClose();
      clearStates();
    }
  };

  return (
    <Main>
      <Navbar changeColor="true" />
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <ClientText>Área do cliente</ClientText>
        <ClientNameInfo>
          <div className="client-info">
            <div className="client-info-container">
              <div className="client-info-texts">
                <div className="name-info">
                  <h2 className="gray-text">Nome</h2>
                  <span className="bold-text">{companyData?.name}</span>
                </div>
                <div className="cnpj-info">
                  <h2 className="gray-text">CNPJ</h2>
                  <span className="bold-text">
                    {formatCNPJ(companyData?.cnpj)}
                  </span>
                </div>
                <div className="initials-info">
                  <h2 className="gray-text">SIGLA</h2>
                  <span className="bold-text">{companyData?.initials}</span>
                </div>
                <div className="inicio-livre-info">
                  <h2 className="gray-text">
                    {companyData?.status === "EM_ADESAO"
                      ? "PREVISÃO DE MIGRAÇÃO"
                      : "INÍCIO LIVRE"}
                  </h2>
                  <span className="bold-text">
                    {convertDate(companyData?.start_acl)}
                  </span>
                </div>
              </div>
              <Button
                variant="primary"
                onClick={handleShow}
                style={{
                  width: "auto",
                  background: "#fff",
                  border: 0,
                  color: "#0072ce",
                  // marginBottom: "1rem",
                  position: "relative",
                  right: "10px",
                }}
              >
                EDITAR
              </Button>
            </div>
            <div className="client-logo">
              {companyLogo && (
                <div className="logo">
                  <img src={companyLogo} alt="Logo" />
                </div>
              )}
            </div>
          </div>
        </ClientNameInfo>

        <StatusMigrationAreaComponent
          companyDataCheck={companyData?.check}
          type="company"
        />

        {companyData?.check?.aprovacaoCheck ? (
          companyData?.profiles?.length > 0 && (
            <>
              {companyData && <CustoEconomia companyData={companyData} />}
              {profiles && (
                <ConsumeLine monthlyData={profiles} profiles={profiles} />
              )}
            </>
          )
        ) : (
          <div className="right-text-center">
            <h1>
              Histórico e gráficos serão disponibilizados após a migração ser
              concluída
            </h1>
          </div>
        )}
        {/* {prevData && <ProjecaoConsumo prevData={prevData} />} */}
        {/* {prevData && <XPTO prevData={prevData} />} */}
        {/* {monthlyData && <Historico monthlyData={monthlyData} />} */}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Edite as suas informações</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "8px" }}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nome de exibição</Form.Label>
              <Form.Control
                type="text"
                placeholder={`${userData?.name}`}
                autoFocus
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder={`${userData?.email}`}
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Nova senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Confirme sua nova senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={saveInfo} disabled={disabled}>
            Salvar informações
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </Main>
  );
};

export default ClientDashboard;
