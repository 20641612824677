import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Accordion, Card, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../Components/Navbar/index";
import Footer from "../Components/Footer/index";
import ReceitaCard from "../Components/Agente/ReceitaCard";
import UsinaAccordion from "../Components/Agente/UsinaAccordion";
import AgenteChart from "../Components/Agente/AgenteChart";
import Medias from "../Components/Medias";
import TotalChart from "../Components/GraficoTotal";
import LastroChart from "../Components/Lastro";
import api from "../Services/apiSimulation";
import arrowleft from "../Assets/arrowleft.png";
import { getToken, getUserId, logout } from "../Services/auth";
import AccordionRight from "../Components/AccordionRight";
import AgenteChartPurchaseSale from "../Components/Agente/AgenteChartPurchaseSale";
import newSimulatorAPI from "../Services/newSimulationAPI";

// ==== TYPE DECLARATIONS ====

interface AgentData {
  codAgente: number;
  name: string;
  sigla: string;
  cnpj: string;
  perfils: {
    codPerfil: number;
    sigla: string;
    classe: string;
    categoria: string;
    submercado: string;
    perfilVarejista: boolean;
    codagente: number;
    usinas: {
      codAtivo: number;
      sigla: string;
    }[];
    consume: {
      id: number;
      loggedAt: string;
      value: number;
      codPerfil: number;
    }[];
    Generation: {
      id: number;
      loggedAt: string;
      value: number;
      codPerfil: number;
    }[];
    PurchaseContracts: {
      id: number;
      loggedAt: string;
      value: number;
      codPerfil: number;
    }[];
    SalesContract: {
      id: number;
      loggedAt: string;
      value: number;
      codPerfil: number;
    }[];
  }[];
}

interface CNPJData {
  tipo: string;
  municipio: string;
  uf: string;
  cep: string;
  numero: string;
  logradouro: string;
  complemento: string;
  abertura: string;
  email: string;
  qs: { nome: string; cargo: string }[];
}

interface LastroData {
  consumes: {
    loggedAt: string;
    value: number;
  }[];
  generation: {
    loggedAt: string;
    value: number;
  }[];
  purchaseContracts: {
    loggedAt: string;
    value: number;
  }[];
  salesContracts: {
    loggedAt: string;
    value: number;
  }[];
  lastro: {
    loggedAt: string;
    value: number;
  }[];
}

interface MainContentProps {
  isOpen: boolean;
}

// ==== STYLED COMPONENTS ====

const Main = styled.div``;

const MainContent = styled.div<MainContentProps>`
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 50px;
  background-color: #fff;
  min-height: 20em;

  .accordion {
    border: 0;
  }

  .card-header {
    border: 1px solid #0072ce;
  }
`;

const LogoArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .back {
    width: 20px;
    height: auto;
    cursor: pointer;
  }

  .back-area {
    cursor: pointer;
  }

  .logo {
    height: 100px;
  }

  span {
    font-size: 16px;
    color: #0072ce;
    text-transform: uppercase;
    margin: 0 40px 0 5px;
  }
`;

const AgentContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const PerfilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 0;
  margin-top: 35px;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 35px;
`;

const PerfilNamePlusIcon = styled.span`
  display: flex;
  align-items: center;
`;

const PerfilName = styled.span`
  color: #212322;
  padding-left: 1em;
`;

const PerfilContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const PerfilColumn = styled.div`
  width: 50%;
`;
const PerfilDataContainer = styled.div`
  margin-bottom: 1em;
`;

const PerfilDataTitle = styled.div`
  text-transform: uppercase;
  color: #353535;
  font-size: 14px;
`;

const NotFoundBox = styled.div`
  align-items: center;
  text-align: center;
  padding: 1em;
  margin: 0.5em;
  border-radius: 5px;
  background-color: white;
`;

const NotFoundHeader1 = styled.h2`
  font-size: 36px;
`;

const FontAreaText = styled.div`
  margin-top: 1rem;
  margin-bottom: 40px;

  span {
    font-weight: 300;
    font-size: 32px;
    color: #212322;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #7e7e82;
  }
`;

const ButtonNovaPesquisa = styled.div`
  margin: auto;
  background-color: #0367c7;
  color: white;
  width: 226px;
  height: 53px;
  font-weight: 300;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  font-family: "Roboto", sans-serif;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 80px;
  cursor: pointer;
`;

const ClickableText = styled.span`
  cursor: pointer;
`;

const GraphContainer = styled.div`
  width: 100%;
  padding-top: 2em;
`;

function RenderEnergyIcon(perfilName: string) {
  if (perfilName.toLowerCase().includes("solar")) {
    return (
      <img
        src={require("../Assets/sun.svg")}
        alt="not found icon"
        style={{ width: "1.5em", height: "1.5em" }}
      ></img>
    );
  } else {
    return (
      <img
        src={require("../Assets/zap.svg")}
        alt="not found icon"
        style={{ width: "1.5em", height: "1.5em" }}
      ></img>
    );
  }
}

function backPage() {
  window.history.back();
}

function AgentPage() {
  let { agentId } = useParams<{ agentId: string }>();

  const [agentData, setAgentData] = useState<AgentData>();
  const [cnpjData, setCnpjData] = useState<CNPJData>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [companyLogo, setCompanyLogo] = useState<string | undefined>(undefined);
  const [lastro, setLastro] = useState<LastroData>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [role, setRole] = useState("");
  const [clientLogo, setClientLogo] = useState("");

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // ++++ GEOCODING WITH GOOGLE MAPS API ++++

  async function getAgent(id: string) {
    const my_token = getToken();

    const response = await newSimulatorAPI.get(`agentes/${id}`, {
      headers: { authorization: "Bearer " + my_token },
    });
    const responseData = response.data;

    setDataLoaded(true);
    if (responseData.statusCode === undefined) {
      setAgentData(responseData);
      let cnpjDigits = responseData.cnpj.match(new RegExp(/\d+/g)).join("");
      if (cnpjDigits.length < 14) {
        cnpjDigits = "0".concat(cnpjDigits);
      }
      const cnpjResponse = await newSimulatorAPI.get(
        `agentes/${id}/cnpj/${cnpjDigits}`,
        {
          headers: { authorization: "Bearer " + my_token },
        }
      );
      const cnpjResponseData = await cnpjResponse.data;

      setCnpjData({
        tipo: cnpjResponseData.tipo,
        municipio: cnpjResponseData.municipio,
        uf: cnpjResponseData.uf,
        cep: cnpjResponseData.cep,
        numero: cnpjResponseData.numero,
        logradouro: cnpjResponseData.logradouro,
        complemento: cnpjResponseData.complemento,
        abertura: cnpjResponseData.abertura,
        email: cnpjResponseData.email,
        qs: cnpjResponseData.qsa?.map((e: { nome: string; qual: string }) => {
          return { nome: e.nome, cargo: e.qual };
        }),
      });
      const responseLastro = await newSimulatorAPI.get(`agentes/${id}/lastro`, {
        headers: { authorization: "Bearer " + my_token },
      });
      const lastroData = await responseLastro.data;
      setLastro(lastroData);
    } else {
      setNotFound(true);
    }
  }
  useEffect(() => {
    getAgent(agentId);
  }, [agentId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const my_token = getToken();
        const id = getUserId();

        var response = await api.get(`/users/${id}`, {
          headers: { authorization: "Bearer " + my_token },
        });
        setCompanyLogo(response.data.partner_company.logo);
      } catch (err) {
        //
      }
    }
    fetchData();
  }, []);

  if (dataLoaded && !notFound) {
    return (
      <Main>
        <Navbar changeColor="true" />
        <Container>
          <LogoArea style={{ marginTop: "50px" }}>
            <img
              className="back"
              src={arrowleft}
              alt="Voltar"
              onClick={backPage}
            />
            <div className="back-area">
              <span onClick={backPage}>Voltar</span>

              {role === "parceiro" && (
                <img className="logo" src={companyLogo} alt="Logo empresa" />
              )}
              {role === "cliente" && (
                <img className="logo" src={clientLogo} alt="Logo empresa" />
              )}
            </div>
          </LogoArea>
          <h1
            style={{
              marginTop: "60px",
              color: "#212322",
              fontWeight: 300,
              fontSize: 32,
            }}
          >
            {agentData?.name}
          </h1>
          <AgentContainer>
            {agentData && cnpjData && (
              <ReceitaCard agentData={agentData} cnpjData={cnpjData} />
            )}
            <FontAreaText>
              <span>Escolha uma unidade consumidora</span>
              <p>Veja dados das unidades vinculadas a Matriz</p>
            </FontAreaText>

            {agentData?.perfils
              ?.sort((a, b) => a.codPerfil - b.codPerfil)
              .map((p, i) => {
                if (p.categoria === "Geração" && p?.usinas?.length > 0) {
                  return <UsinaAccordion p={p} i={i} />;
                } else {
                  return (
                    <Accordion
                      className={` ${isOpen ? "" : ""}`}
                      onClick={handleToggle}
                    >
                      <PerfilContainer>
                        <Card style={{ width: "100%" }}>
                          <Accordion.Toggle
                            as={Card.Header}
                            variant="link"
                            eventKey={`perf${i}`}
                            flush="true"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              height: "80px",
                              alignItems: "center",
                              backgroundColor: "white",
                              borderRadius: "5px 5px 5px 5px",
                              paddingLeft: "2em",
                            }}
                          >
                            <PerfilNamePlusIcon>
                              <PerfilName>
                                <strong>{p.sigla}</strong>
                              </PerfilName>
                            </PerfilNamePlusIcon>
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              size="lg"
                              style={{ marginRight: "1.2em" }}
                            />
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey={`perf${i}`}>
                            <Card.Body>
                              <PerfilContent>
                                <PerfilColumn>
                                  <PerfilDataContainer>
                                    <PerfilDataTitle>CÓDIGO</PerfilDataTitle>
                                    {p.codPerfil}
                                  </PerfilDataContainer>
                                  <PerfilDataContainer>
                                    <PerfilDataTitle>
                                      submercado
                                    </PerfilDataTitle>
                                    {p.submercado}
                                  </PerfilDataContainer>
                                </PerfilColumn>
                                <PerfilColumn>
                                  <PerfilDataContainer>
                                    <PerfilDataTitle>categoria</PerfilDataTitle>
                                    {p.categoria}
                                  </PerfilDataContainer>
                                  <PerfilDataContainer>
                                    <PerfilDataTitle>classe</PerfilDataTitle>
                                    {p.classe}
                                  </PerfilDataContainer>
                                </PerfilColumn>
                              </PerfilContent>
                              {/* {p.consume.length > 0 && (
                                <AgenteChart
                                  options={{ title: "Consumo" }}
                                  data={p.consume}
                                />
                              )} */}
                              {(p.PurchaseContracts.length > 0 ||
                                p.SalesContract.length > 0 ||
                                p.consume.length > 0) && (
                                <AgenteChartPurchaseSale
                                  purchaseContracts={p.PurchaseContracts}
                                  salesContracts={p.SalesContract}
                                  consume={p.consume}
                                />
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </PerfilContainer>
                    </Accordion>
                  );
                }
              })}
            {cnpjData && lastro && (
              <>
                <Medias uf={cnpjData?.uf} consumo={lastro?.consumes} />
                {/* <TotalChart consumo={lastro?.consumes} /> */}
                <LastroChart lastro={lastro?.lastro} />
              </>
            )}

            <ButtonNovaPesquisa
              onClick={() => {
                window.location.href = "/pesquisa-mercado-livre";
              }}
            >
              <ClickableText
                onClick={() => {
                  window.location.href = "/pesquisa-mercado-livre";
                }}
              >
                NOVA PESQUISA
              </ClickableText>
            </ButtonNovaPesquisa>
          </AgentContainer>
        </Container>
        <Footer />
      </Main>
    );
  } else if (dataLoaded && notFound) {
    return (
      <Main>
        <Navbar changeColor="true" />
        <MainContent isOpen={isOpen}>
          <AgentContainer>
            <NotFoundBox>
              <NotFoundHeader1>Agente não encontrado</NotFoundHeader1>
              <img
                src={require("../Assets/404.png")}
                alt="not found icon"
              ></img>
              <h5>Acredita se tratar de um erro?</h5>
              <h5>
                <Link to="">Enviar ticket pro suporte</Link>
              </h5>
            </NotFoundBox>
          </AgentContainer>
        </MainContent>
        <Footer />
      </Main>
    );
  } else {
    return (
      <Main>
        <Navbar changeColor="true" />
        <MainContent isOpen={isOpen}> </MainContent>
        <Footer />
      </Main>
    );
  }
}

export default AgentPage;
