import React from "react";

import goCadAtivo from "../../Assets/cadAtivoGo.png";
import cadAtivoOk from "../../Assets/cadAtivoOk.png";
import cadInativoOk from "../../Assets/inativoOk.png";
import { useHistory } from "react-router-dom";

import "./style.css";

function formatCurrency(n) {
  if (n) {
    return new Intl.NumberFormat("pt-BR", {}).format(n);
  }
}

const CadastrosAtivos = ({ Affiliates, economiaTotal }) => {
  const history = useHistory();

  function convertDate(date) {
    return `${new Date(date).toLocaleDateString("pt-br").split(",")[0]}`;
  }

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  const handleGoToAffiliate = (affiliateId) => {
    localStorage.setItem("affiliateId", affiliateId);
    history.push("/empresa");
  };

  return (
    <div className="cadastros-container">
      <div className="cadastros-container-section">
        <div>
          <h1>Clientes indicados</h1>
          <h2>
            Lista de clientes indicados por você que estāo ativos na nossa base
          </h2>
        </div>
        <div className="economy-area">
          <div className="value-card">
            {economiaTotal ? (
              <>
                <h2>Economia total dos clientes indicados</h2>
                <p>R$ {formatCurrency(economiaTotal)}</p>
              </>
            ) : (
              <>
                <h2>Economia total dos clientes indicados</h2>
                <p>R$ 0,00</p>
              </>
            )}
          </div>
        </div>
      </div>

      {Affiliates.length > 0 ? (
        <div className="cadastros-grid">
          {Affiliates.map((affiliate) => {
            return (
              <div className="container-afilliates">
                <div className="cadastros-item" key={affiliate.initials}>
                  <div className="cadastros-item-info">
                    <div className="cadastros-item1">
                      {affiliate.check.aprovacaoCheck ? (
                        <>
                          <img
                            src={cadAtivoOk}
                            alt="ok"
                            width="10"
                            height="10"
                          />
                          <h3>Migração completa</h3>
                        </>
                      ) : (
                        <>
                          <img
                            src={cadInativoOk}
                            alt="ok"
                            width="10"
                            height="10"
                          />
                          <h3>Migração em progresso</h3>
                        </>
                      )}
                    </div>
                    <div className="cadastros-item2">
                      <h4>{affiliate.initials}</h4>
                      <h5>{formatCNPJ(affiliate.cnpj)}</h5>
                    </div>
                    <div className="cadastros-item3">
                      {affiliate.check.aprovacaoCheck && (
                        <h6>
                          O cliente migrou em {convertDate(affiliate.start_acl)}
                        </h6>
                      )}
                    </div>
                  </div>
                  <img
                    className="logo-affiliate"
                    src={affiliate.logo}
                    alt="logo-affiliate"
                    width="60"
                    height="auto"
                  />
                </div>

                <div className="button-container ">
                  <div
                    onClick={() => handleGoToAffiliate(affiliate.id)}
                    className="button-focus"
                  >
                    VER
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <h3 style={{ margin: "20px 0 0 0", width: "100%", fontSize: 18 }}>
          Nenhum cliente foi indicado ainda
        </h3>
      )}
    </div>
  );
};
export default CadastrosAtivos;
