import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { getToken } from "../../Services/auth";
import api from "../../Services/api";

function PartnerEditInfo({ partnerInfo }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState(partnerInfo.name);
  const [email, setEmail] = useState(partnerInfo.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearStates = () => {
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setDisabled(false);
  };

  const saveInfo = async () => {
    setDisabled(true);

    if (password !== confirmPassword) {
      window.alert("As duas senhas precisam ser iguais!");
      clearStates();
      handleClose();
      return;
    }

    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const data = {
      password: password ? password : undefined,
      name: name ? name : undefined,
      email: email ? email : undefined,
    };

    try {
      await api.patch(`/users/${partnerInfo.id}`, data, {
        headers,
      });

      handleClose();
      clearStates();
      window.alert("Suas novas informações foram salvas com sucesso!");
      window.location.reload();
    } catch (e) {
      window.alert(
        "Erro ao tentar salvar suas informações, por favor, verifique se os campos estão corretos!"
      );
      handleClose();
      clearStates();
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{
          width: 80,
          background: "#fff",
          border: 0,
          color: "#0072ce",
          position: "relative",
          right: "10px",
        }}
      >
        EDITAR
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Edite as suas informações</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "8px" }}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nome de exibição</Form.Label>
              <Form.Control
                type="text"
                placeholder={`${partnerInfo.name}`}
                autoFocus
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder={`${partnerInfo.email}`}
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Nova senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Confirme sua nova senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={saveInfo} disabled={disabled}>
            Salvar informações
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PartnerEditInfo;
